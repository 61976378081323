<template>
  <div class="search-block">
    <div class="row">
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
          <input type="text"  v-model="form.order_number"><span/>
          <span class="input-block__name" :class="form.order_number.length ?'active' : null" >Order Number</span>
      </label>
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
        <input type="text"  v-model="form.ndc"><span/>
        <span class="input-block__name" :class="form.ndc.length ?'active' : null" >Search NDC</span>
      </label>
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
        <input type="text"  v-model="form.product_name"><span/>
        <span class="input-block__name" :class="form.product_name.length ?'active' : null" >Search by Name</span>
      </label>
      <label class="input-block col-xl-3 col-md-3 col-sm-12">
        <input type="text"  v-model="form.seller"><span/>
        <span class="input-block__name" :class="form.seller.length ?'active' : null" >Search by Name</span>
      </label>
      <label class="input-block col-md-5 mt-3">
        <input type="date"  v-model="form.created_at_from"><span/>
        <span class="input-block__name active">Start Date*</span>
      </label>
      <label class="input-block col-md-5 mt-3">
        <input type="date"  v-model="form.created_at_to"><span/>
        <span class="input-block__name active">end Date*</span>
      </label>
      <div class="col-md-2 col-sm-12 mt-3">
        <div class="d-flex justify-center">
          <button class="btn grey" @click="searchOrders()">Search</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  data(){
    return{
      form:{
        order_number:'',
        ndc:'',
        product_name:'',
        seller:'',
        created_at_from:'',
        created_at_to:'',
      }
    }
  },
  computed:{
    ...mapGetters({
      sellOrderParams: 'orders/sellOrderParams'
    })
  },
  methods:{
    searchOrders(){
      const payload = [];
      Object.keys(this.form).map(item=>{
          payload[item] = this.form[item];
      })
      this.changeSellOrderParams(payload)

      const searchPayload = {};
      Object.keys(this.sellOrderParams).map(item=>{
        if(this.sellOrderParams[item].length){
          searchPayload[item] = this.sellOrderParams[item];
        }
      })
      this.getSoldOrders(searchPayload).then(res=>{
        if(!res.status){
          this.$toasted.error(JSON.stringify(res.data.errors), {
            duration:3000
          })
        }
      })
    },
    ...mapActions({
      getSoldOrders: 'orders/getSoldOrders'
    }),
    ...mapMutations({
      changeSellOrderParams: 'orders/changeSellOrderParams'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.search-block{
  width: 100%;
  padding: 40px 100px;
  border-radius: 27px;
  background-color: $white-grey;
}
</style>
